import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "good-social-skills"
    }}>{`Good social skills`}</h1>
    <p>{`To help your teenager develop good social skills (and good friends), you can:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Encourage your teenager to do kind things for others.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set an example for your teenager by the way you communicate in social situations and manage social challenges. For tips on assertive communication, see the `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`Calm versus Conflict`}</i></GoTo>{` module.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Show your teenager healthy friendships by having good friends who are trustworthy, loyal and fun around `}<strong parentName="p">{`you`}</strong>{`!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Talk through any social difficulties or friendship troubles that your teenager may have. For tips on managing friendship troubles and problem solving, see the `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`Partners in Problem Solving`}</i></GoTo>{` module.`}</p>
      </li>
    </ul>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m6/31.svg" alt="riding a bike with friend" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      